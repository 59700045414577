import 'animate.css'
import 'antd/dist/antd.css'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../src/components/layout/styles/index.scss'

import absoluteUrl from 'next-absolute-url'
import React, { useEffect, useState } from 'react'
import { getApolloClient } from '../utils/apolloClient'
import withApollo from 'next-with-apollo'
import { ApolloProvider } from '@apollo/client'
import { AuthProvider } from '../src/wrappers/Auth'
import { StoreContextProvider } from '../utils/context/store/'
import {
  getToken,
  refreshToken,
  checkToken,
  getUserMe,
  setClearToken,
  getUser,
  setMenuPermission,
  getMenuPermission,
  menuStatus,
  inArray
} from '../src/lib/authToken'
import { gql } from 'apollo-boost'
import { useRouter } from 'next/router'
import SimpleReactLightbox from 'simple-react-lightbox'
import jwt from 'jsonwebtoken'
import { Button, Result, Space, Spin } from 'antd';

const USER = gql`
  query USER($where: UserWhereUniqueInput!) {
    user(where: $where) {
      status
      role{
        name
        roleAuthorize{
          masterMenu{
            name
            url
            subRoleUrls
          }
        }
      }
    }
  }
`

const MyApp = ({ apollo, Component, pageProps, authenticated, authenticatedAgent, clearTokenStatus }) => {
  const router = useRouter()

  // Redirect Menu Permission Not Found.
  const [getPageStatus, setPageStatus] = useState({
    loading: true,
    status: false
  })

  useEffect(() => {
    try {
      const username = getUserMe(refreshToken())
      const user = apollo.query({
        query: USER,
        fetchPolicy: 'network-only',
        variables: {
          where: {
            username: username,
          },
        },
      })

      user.then(res => {
        if (res.data.user.status !== 'ACTIVE' || clearTokenStatus) {
          setClearToken()
        } else {
          var getMenu = res?.data?.user?.role?.roleAuthorize?.map(a => a["masterMenu"]["url"])
          var getSubMenu = res?.data?.user?.role?.roleAuthorize?.flatMap(a => a["masterMenu"]["subRoleUrls"])
          var cleanMenu = getMenu.concat(getSubMenu).filter(function (i) { return i != null; })
          var token = jwt.sign(JSON.stringify(cleanMenu), 'permissionMenu');
          setMenuPermission(token);

          setPageStatus({
            loading: false,
            status: menuStatus(router.pathname)
          });
        }
      })

    } catch (error) {
      setPageStatus({
        loading: false,
        status: true
      });
    }

    // Check Cookie on website with interval
    const x = setInterval(() => {
      const xx = authenticated ? checkToken() : false
      if (xx === false) {
        clearInterval(x)
      }
    }, 1000)

  }, [apollo, authenticated, router, router.pathname])

  return (
    <SimpleReactLightbox>
      <StoreContextProvider>
        <ApolloProvider client={apollo}>
          <AuthProvider authenticated={authenticated} authenticatedAgent={authenticatedAgent}>
            {getPageStatus.loading ? <Result
              status="404"
              title="Loading"
              subTitle="กรุณารอสักครู่..."
              extra={<>
                <Space>
                  <Spin size="large" />
                </Space></>}
            /> : (!getPageStatus.loading && getPageStatus.status ?
              <Component {...pageProps} /> :
              <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={<Button type="primary" onClick={() => {
                  location.href = "/admin";
                }}>Back Home</Button>}
              />)}
          </AuthProvider>
        </ApolloProvider>
      </StoreContextProvider>
    </SimpleReactLightbox>
  )
}
MyApp.getInitialProps = async ({ Component, ctx }) => {
  let authenticated = false
  let authenticatedAgent = false
  let clearTokenStatus = false
  const request = await ctx.req

  if (request) {
    try {
      const token = getToken(ctx)
      if (token !== undefined) {
        const { protocol, host } = absoluteUrl(ctx.req)
        const authAPIUrl = `${protocol}//${host}/api/auth`

        var myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded")
        var urlencoded = new URLSearchParams()
        urlencoded.append("token", token)

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: urlencoded,
          redirect: 'follow'
        }

        const auth = await fetch(authAPIUrl, requestOptions)
        const authResult = await auth.json()
        if (authResult?.data === false) {
          clearTokenStatus = true
        }
      }

      let user
      user = getUser(token)

      if (
        user?.role?.name !== 'user' &&
        user?.role?.name !== 'agent' &&
        user?.role?.name !== 'admin_agent'
      ) {
        authenticated = true
      }
      if (user?.role?.name === 'agent' || user?.role?.name === 'admin_agent') {
        authenticatedAgent = true
      }
    } catch (error) {
      authenticated = false
    }
  }
  let pageProps = {}
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx)
  }
  return { pageProps, authenticated, authenticatedAgent, clearTokenStatus }
}

export default withApollo(({ ctx, initialState }) => {
  return getApolloClient(ctx, initialState)
})(MyApp)
